import { useState } from "react";
import { Link, generatePath } from "react-router-dom";
import PropTypes from "prop-types";

import { useProtectedAction } from "features/authentication";
import { Button, Shimmer, User } from "ui";
import routes from "default/routes";
import { formatRelativeTime } from "utils/helpers";
import MessageCircleReply from "images/icons/lucide-icons/MessageCircleReply";
import EyeOff from "images/icons/lucide-icons/EyeOff";

import feedbackPropTypes from "../../feedbackSchema";
import { useFeedbackMutations } from "../../api";
import LikeButton from "./LikeButton";
import FeedbackDropDown from "./FeedbackDropDown";

function FeedbackCardLoading() {
  return (
    <div className="old-rounded-4 old-bg-white px-2 py-6">
      <div className="old-d-flex old-align-items-center">
        <Shimmer className="old-bg-blank old-ratio old-ratio-1x1 !size-16 old-rounded-circle" />

        <div className="old-mx-3 old-w-50">
          <Shimmer className="old-bg-blank old-w-100 old-p-2 old-rounded old-h-25" />
          <Shimmer className="old-bg-blank old-w-50 old-p-2 old-mt-2 old-rounded old-h-25" />
        </div>
      </div>

      <Shimmer className="old-bg-blank old-w-100 old-rounded-4 old-mt-3" height={200} />
      <Shimmer className="old-bg-blank old-rounded-4 old-mt-3 old-w-25" height={30} />
    </div>
  );
}

function FeedbackCard({ comment, onReply = {}, submissionId, isOwnSubmission, depth = 0 }) {
  const [showReplies, setShowReplies] = useState(false);
  const toggleReplies = () => setShowReplies((prev) => !prev);

  const { user: author, content, children } = comment;
  const relativeTime = formatRelativeTime(comment.created_at);

  const { likeFeedback, unlikeFeedback, deleteFeedback, hideFeedback, unhideFeedback } =
    useFeedbackMutations(submissionId);

  const [handleLikeToggle] = useProtectedAction(async () => {
    if (comment.liked_by_user) unlikeFeedback.mutateAsync(comment.id);
    else likeFeedback.mutateAsync(comment.id);
  });
  const [handleDeleteComment] = useProtectedAction(async () => deleteFeedback.mutate(comment.id));
  const [handleHideComment] = useProtectedAction(async () => {
    if (comment.hidden_at) unhideFeedback.mutate(comment.id);
    else hideFeedback.mutate(comment.id);
    return setShowReplies(false);
  });

  if (!isOwnSubmission && comment.hidden_at) return null;

  return (
    <div className={`py-2 mx-1 bg-white ${depth > 0 ? "pe-0 ml-3 sm:ml-6" : "border-b-4"}`}>
      <div className="flex space-between items-top gap-2">
        <Link
          to={generatePath(routes.userProfile, { username: author.username })}
          className="flex items-start mr-auto rounded-lg"
        >
          <div className="flex items-center">
            <User
              name={author.username}
              avatarProps={{
                src: author.icon,
                isBordered: true,
              }}
              description={relativeTime}
              as={Link}
              to={generatePath(routes.userProfile, { username: author.username })}
            />
          </div>
        </Link>
        <div>
          <FeedbackDropDown
            comment={comment}
            isOwnSubmission={isOwnSubmission}
            handleDeleteComment={() => handleDeleteComment(comment.id)}
            handleHideComment={() => handleHideComment()}
          />
        </div>
      </div>
      <p className="my-4 ms-1 leading-relaxed text-pretty overflow-clip">{content}</p>
      <div className="flex items-center text-md mb-3">
        {comment.hidden_at ? (
          <div className="flex uppercase text-default-300 bg-default-100 max-h-8 rounded-lg py-1 px-2 h-auto gap-x-2 me-2">
            <EyeOff className="h-6 w-6" />
            Hidden: only you can see this comment
          </div>
        ) : (
          <>
            <LikeButton
              isLiked={comment.liked_by_user}
              likeCount={comment.like_count}
              onLikeToggle={handleLikeToggle}
            />
            {depth === 0 && (
              <Button
                onClick={() => onReply(comment)}
                variant="light"
                type="button"
                radius="md"
                className="ml-4 uppercase"
              >
                <MessageCircleReply className="h-6 w-6" />
                Reply
              </Button>
            )}
            {children.length > 0 && (
              <Button onClick={toggleReplies} variant="light" type="button" radius="md">
                <span>
                  {showReplies
                    ? `Hide Replies (${children.length})`
                    : `View Replies (${children.length})`}
                </span>
              </Button>
            )}
          </>
        )}
      </div>
      <div className={depth === 0 && "mt-2"}>
        {showReplies &&
          children.map((childComment) => (
            <FeedbackCard
              key={childComment.id}
              comment={childComment}
              submissionId={submissionId}
              isOwnSubmission={isOwnSubmission}
              depth={depth + 1}
            />
          ))}
      </div>
    </div>
  );
}
FeedbackCard.propTypes = {
  comment: feedbackPropTypes.isRequired,
  onReply: PropTypes.func,
  submissionId: PropTypes.number.isRequired,
  isOwnSubmission: PropTypes.bool.isRequired,
  depth: PropTypes.number,
};
FeedbackCard.Loading = FeedbackCardLoading;

export default FeedbackCard;
