import { CallToAction } from "ui";
import routes from "default/routes";

export default function LeaderboardBlock(props) {
  return (
    <CallToAction
      title={
        <>
          Oditi
          <br className="old-d-none old-d-xxxl-block" /> Leaderboard
        </>
      }
      description="Oditi Leaderboard showcases the entire community&#39;s favourite designers from all around the world."
      cta="View Leaderboard"
      to={routes.leaderboard}
      trackingName="view leaderboard"
      {...props}
    />
  );
}
