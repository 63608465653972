import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import StarIcon from "@mui/icons-material/Star";
import BoltIcon from "@mui/icons-material/Bolt";
import VerifiedBadge from "icons/VerifiedBadge";
import { Tabs, Tab, Button } from "ui";
import useScroll from "hooks/useScroll";
import { useBriefs } from "features/brief";

export default function Filters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { all: briefs } = useBriefs({ status: ["voting", "judging"] });
  const selectedTab = searchParams.get("t");
  const scrollRef = useRef(null);
  const { atEnd, atStart, scrollRight, scrollLeft } = useScroll(scrollRef);

  return (
    <div className="relative flex justify-center items-center">
      <Tabs
        ref={scrollRef}
        size="lg"
        radius="md"
        color="primary"
        selectedKey={selectedTab}
        defaultSelectedKey="popular"
        onSelectionChange={(key) =>
          setSearchParams(
            (prev) => {
              prev.set("t", key);
              return prev;
            },
            { replace: true },
          )
        }
        classNames={{
          base: "max-w-full flex justify-center",
          tab: "uppercase h-14 sm:h-16 min-w-fit p-0",
          tabContent: "grayscale group-data-[selected=true]:grayscale-0",
          tabList: "gap-3",
          cursor: "rounded-2xl",
        }}
      >
        <Tab
          key="popular"
          title={
            <div className="flex flex-col items-center justify-center h-full min-w-fit">
              <BoltIcon />
              <span className="text-sm md:text-md uppercase px-4">Popular</span>
            </div>
          }
        />
        <Tab
          key="spotlight"
          title={
            <div className="flex flex-col items-center justify-center h-full min-w-fit">
              <StarIcon />
              <span className="text-sm md:text-md uppercase px-4">Spotlight</span>
            </div>
          }
        />
        <Tab
          key="veterans"
          className="me-2 md:me-6"
          title={
            <div className="flex flex-col items-center justify-center h-full min-w-fit">
              <VerifiedBadge className="!size-5 mb-1 group-data-[selected=true]:text-secondary" />
              <span className="text-sm md:text-md uppercase px-4">Veterans</span>
            </div>
          }
        />
        {briefs.map((brief) => (
          <Tab
            key={brief.id}
            title={
              <div className="flex h-full min-w-fit">
                <img
                  className="object-cover h-14 sm:h-16 rounded-2xl aspect-4/3"
                  src={brief.thumbnail}
                  alt="organisation icon"
                />
                <div className="flex justify-center items-center flex-col">
                  <span className="text-xl ps-4 pe-5">{brief.organisation?.name}</span>
                </div>
              </div>
            }
          />
        ))}
      </Tabs>

      <Button
        isIconOnly
        size="sm"
        color="primary"
        variant="light"
        className={`absolute left-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atStart && "hidden"}`}
        onClick={() => scrollLeft()}
      >
        <ChevronLeft color="inherit" />
      </Button>
      <Button
        isIconOnly
        color="primary"
        variant="light"
        className={`absolute right-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atEnd && "hidden"}`}
        size="sm"
        onClick={() => scrollRight()}
      >
        <ChevronRight />
      </Button>
    </div>
  );
}
